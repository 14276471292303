<template>
  <div class="d-flex justify-center align-center">
    <div class="avatar" @click="onclick">
      <v-img
        v-if="modelValue"
        class="img"
        max-width="150"
        max-height="150"
        :src="imageToDisplay"
        @loadstart="loadstart"
        @load="load"
      >
        <template #placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-2"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card
        v-else
        style="border: 1px dashed #999"
        flat
        class="plus d-flex justify-center align-center"
      >
        <v-icon>mdi-plus</v-icon>
      </v-card>
      <input
        ref="fileselect"
        class="hidden"
        type="file"
        accept="image/*"
        @change="fileSelected"
      />
    </div>
    <v-progress-circular
      v-show="loading"
      indeterminate
      color="primary"
      class="progress"
    ></v-progress-circular>
    <v-progress-circular
      v-show="progress > 0"
      color="primary"
      class="progress"
      :value="progress"
    ></v-progress-circular>
  </div>
</template>
<script>
import httpConfig from '@/config/http.config';
export default {
  name: 'KCheckbox',
  props: {
    isMulti: { type: Boolean, default: false },
    value: { type: String, default: '' },
    text: { type: String, default: '' },
    label: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    clearable: { type: Boolean, default: true },
    type: { type: String, default: 'text' },
    disabled: { type: Boolean, default: false },
    fileDomain: { type: String, default: '' },
  },
  data() {
    return {
      commonUrl: httpConfig.imgUrl,
      imageToDisplay: '',
      loading: false,
      progress: 0,
      modelValue: null,
      emptyArray: [],
    };
  },
  computed: {
    defaultPlaceholder() {
      return this.placeholder === '' ? '请输入' + this.label : this.placeholder;
    },
    cssVars() {
      return {
        '--labelWidth': this.labelWidth,
        '--inputWidth': this.inputWidth,
      };
    },
  },
  watch: {
    value() {
      this.modelValue = this.value;
    },
    modelValue() {
      this.$emit('input', this.modelValue);
    },
  },
  mounted() {
    // eslint-disable-next-line no-debugger
    debugger;
    this.modelValue = this.value;
    this.imageToDisplay = this.commonUrl + this.modelValue;
  },

  methods: {
    onclick() {
      this.$refs.fileselect.click();
    },
    fileSelected() {
      let files = this.$refs.fileselect.files;
      let size = files[0].size;
      var formData = new FormData();
      formData.append('file', files[0]);
      this.$refs.fileselect.value = '';
      this.$api.file
        .upload(formData, (event) => this.doUploadProgress(event, size))
        .then((res) => {
          if (res.data && res.data.length > 0 && res.data[0]) {
            var avatar = res.data[0];
            if (avatar.fileNames && avatar.fileNames.length > 0) {
              this.imageToDisplay = this.commonUrl + avatar.fileNames[0];
              this.modelValue = avatar.fileNames[0];
            }
          }
        })
        .finally(() => {
          this.progress = 0;
        });
    },
    doUploadProgress(event, size) {
      this.progress = (event.loaded / size) * 100;
      console.info(this.progress);
    },
    loadstart() {
      this.loading = true;
    },
    load() {
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.img {
  height: 100%;
}
.hidden {
  display: none;
}

.avatar {
  height: 150px;
  width: 150px;
  cursor: pointer;
}

.plus {
  height: 100%;
}
.progress {
  position: absolute;
}
</style>
